<template>
  <div class="toast">
    <div class="container">
      <p><fa-icon id="close" @click="close()" :icon="['fa', 'times']" /></p>
      <div class="text">
        <h1>{{$t('toast.h1')}}</h1>
        <h1>
          {{$t("toast.h12")}}
          <fa-icon id="smile" :icon="['fa', 'smile-wink']" />
        </h1>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    close() {
      let toast = document.querySelector(".toast");
      toast.style.display = "none";
      window.location.reload();
    },
  },
};
</script>

<style scoped>
.toast {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(6px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
}
.container {
  width: 400px;
  height: 250px;
  background: #fff;
  border-radius: 10px;
}
#close {
  color: #ff9b13;
  float: right;
  margin: 20px 20px 0px 0px;
  font-size: 20px;
  cursor: pointer;
}
.text {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: rgb(78, 78, 78);
  text-align: center;
  padding: 10px;
  margin-top: 55px;
}
.text h1 {
  font-weight: 400;
  font-size: 25px;
}
#smile {
  color: #ff9b13;
}

/* RESPONSIVE */
@media (max-width: 850px) {
  .container {
    width: 300px;
    height: 150px;
  }
 .text h1{
    font-size: 18px;
    margin-top: -5px;
  }
}
</style>