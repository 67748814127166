<template>
  <div class="feedback">
    <h1>{{ $t("feedback.h1") }}</h1>

    <!--stars-->
    <div class="stars">
      <input
        type="radio"
        value="5"
        name="rate"
        id="rate5"
        @click="checkValues('rate5')"
      />
      <label for="rate5"><fa-icon :icon="['fas', 'star']" /></label>

      <input
        type="radio"
        value="4"
        name="rate"
        id="rate4"
        @click="checkValues('rate4')"
      />
      <label for="rate4"><fa-icon :icon="['fas', 'star']" /></label>

      <input
        type="radio"
        value="3"
        name="rate"
        id="rate3"
        @click="checkValues('rate3')"
      />
      <label for="rate3"><fa-icon :icon="['fas', 'star']" /></label>

      <input
        type="radio"
        value="2"
        name="rate"
        id="rate2"
        @click="checkValues('rate2')"
      />
      <label for="rate2"><fa-icon :icon="['fas', 'star']" /></label>

      <input
        type="radio"
        value="1"
        name="rate"
        id="rate1"
        @click="checkValues('rate1')"
      />
      <label for="rate1"><fa-icon :icon="['fas', 'star']" /></label>

      <!--phrase that changes according to selected stars-->
      <p id="reference">{{ value }}</p>
    </div>

    <!-- textarea -->
    <form @click.prevent="">
      <textarea
        name="textarea"
        id="textarea"
        cols="30"
        rows="5"
        :placeholder="placeholder"
      ></textarea>
      <button id="btnSend" @click="sendDataToFirebase">
        {{ $t("feedback.btn") }}
      </button>
    </form>

    <!-- Loading icon when click send form data -->
    <span v-if="loading" id="syncIcon" class="material-icons-outlined">
      sync
    </span>

    <!-- toast confirmation when form data is sended -->
    <div class="toast" v-if="toast === true">
      <ToastForm />
    </div>
  </div>
</template>

<script>
import ToastForm from "./ToastForm.vue";
export default {
  data() {
    return {
      rateinfo5: this.$t("feedback.rate5"),
      rateinfo4: this.$t("feedback.rate4"),
      rateinfo3: this.$t("feedback.rate3"),
      rateinfo2: this.$t("feedback.rate2"),
      rateinfo1: this.$t("feedback.rate1"),
      placeholder: this.$t("feedback.placeholder"),
      value: "",
      rating: "",
      opinion: "",
      toast: false,
      loading: false,
    };
  },
  components: {
    ToastForm,
  },
  methods: {
    // condition function in order to display dynamic phrases according to selected stars
    checkValues(rate) {
      let radio = document.querySelector("#" + rate);
      let textarea = document.querySelector("textarea");

      this.value = radio;

      switch (rate) {
        case "rate5":
          this.value = this.rateinfo5;
          this.rating = radio.value;
          this.opinion = textarea.value;
          break;
        case "rate4":
          this.value = this.rateinfo4;
          this.rating = radio.value;
          this.opinion = textarea.value;
          break;
        case "rate3":
          this.value = this.rateinfo3;
          this.rating = radio.value;
          this.opinion = textarea.value;
          break;
        case "rate2":
          this.value = this.rateinfo2;
          this.rating = radio.value;
          this.opinion = textarea.value;
          break;
        case "rate1":
          this.value = this.rateinfo1;
          this.rating = radio.value;
          this.opinion = textarea.value;
          break;
        default:
          this.value = "";
          this.rating = "";
          this.opinion = "";
          this.opinion = "";
          this.toast = false;
      }
    },
    // function to send data to firebase
    async sendDataToFirebase() {
      const ref = this.$firebase.database().ref("rating");
      const id = ref.push().key;
      let textarea = document.querySelector("textarea");
      let button = document.querySelector("#btnSend");

      this.opinion = textarea.value; // received values of textarea

      // mount payload
      if (this.rating != "") {
        const payload = {
          id,
          receipt: null,
          rating: this.rating,
          opinion: this.opinion,
        };

        this.loading = true; // sync icon is visible
        button.setAttribute("disabled", true); // disable button
        button.style.background = "#c5c5c5"

        // send to firebase
        await ref.child(id).set(payload, (err) => {
          if (err) {
            alert(err);
          } else {
            this.rating = "";
            this.opinion = "";
            this.value = "";
            this.toast = true;
          }
          this.teste = false; // sync icon is hidden
          button.setAttribute("disabled", false); // enable button
        });
      } else {
        this.value = this.$t("feedback.default");
      }
    },
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.feedback {
  width: 100%;
  height: 100%;
  padding: 0 120px 120px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #344146;
}
.feedback h1 {
  font-size: 35px;
  font-family: "ubuntu", sans-serif;
  font-weight: bold;
  padding-bottom: 50px;
  color: #ff9b13;
}
.stars input {
  display: none;
}
.stars label {
  font-size: 35px;
  color: #fff;
  padding: 20px 80px;
  float: right;
  cursor: pointer;
  transition: 0.4s;
}
.stars p {
  color: #fff;
}
input:checked ~ label,
input:checked ~ #reference {
  color: #ff9b13;
}
input#rate5:checked ~ label,
input#rate5:checked ~ #reference {
  color: rgb(255, 225, 0);
  text-shadow: 0 0 20px #ff9500;
}
#reference {
  font-size: 28px;
  transition: all ease 0.2s;
  text-align: center;
}
form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
form textarea {
  background: #ffffff;
  width: 600px;
  resize: none;
  color: #344146;
  outline: none;
  padding: 20px;
  margin-top: 20px;
  border: 0;
  border-radius: 10px;
  transition: all ease 0.2s;
}
form button {
  width: 100px;
  padding: 5px 10px;
  margin: 20px;
  border: none;
  background: #ff9500;
  color: #fff;
}
form button:hover {
  background: #fab85b;
}
#syncIcon {
  font-size: 40px;
  color: #ff9500;
  animation: rotate 1s linear infinite;
}
@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(-360deg);
  }
}
/* RESPONSIVE */
@media (max-width: 1200px) {
  .feedback {
    padding: 20px;
  }
  .feedback h1 {
    text-align: center;
    font-size: 35px;
  }
  .stars #reference {
    font-size: 22px;
  }
  .stars label {
    padding: 20px 50px;
  }
  form textarea {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .stars #reference {
    font-size: 22px;
  }
}
@media (max-width: 654px) {
  .stars label {
    font-size: 25px;
  }
}
@media (max-width: 460px) {
  .stars #reference {
    font-size: 12px;
  }
  .stars label {
    padding: 20px;
  }
}
@media (max-width: 375px) {
  .stars label {
    padding: 18px;
  }
}
@media (max-width: 360px) {
  .stars label {
    padding: 15px;
  }
}
@media (max-width: 320px) {
  .stars label {
    padding: 13px;
  }
}
</style>