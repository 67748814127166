<template>
  <div id="contact">
    <div class="content">
      <div class="form">
        <form @submit.prevent="sendDataToFirebase">
          <p>{{ $t("contact.pForm") }}</p>

          <span>{{ $t("contact.spanNome") }}</span>
          <input type="text" id="nome" v-model="name" />

          <span>{{ $t("contact.spanPais") }}</span>
          <select id="select" v-model="nacionality" required>
            <option v-for="index in country" :key="index.ordem" :value="index.nome" required>{{ index.nome }}</option>
          </select>
          
          <span>{{ $t("contact.spanIdade") }}</span>
          <input type="number" id="idade" v-model="age" required />
          
          <span>{{ $t("contact.spanGender") }}</span>
          <select id="select" v-model="gender" required>
            <option value="M">{{ $t("contact.genderOpt1") }}</option>
            <option value="F">{{ $t("contact.genderOpt2") }}</option>
            <option value="lgbt">{{ $t("contact.genderOpt3") }}</option>
          </select>

          <span v-if="gender == 'lgbt'" id="gender2Text">{{$t("contact.spanGender2")}}</span>
          <input type="text" v-if="gender == 'lgbt'" v-model="gender2" required />

          <span>{{ $t("contact.spanTel") }}</span>
          <input type="tel" id="tel" v-model="tel" v-mask="'(###) #########'"/>

          <span>{{ $t("contact.spanEmail") }}</span>
          <input type="email" v-model="email" />

          <button id="btn" type="submit">{{ $t("contact.btnSubmit") }}</button>
          <br />
        </form>

          <!-- Loading icon when click send form data -->
          <span v-if="loading" id="syncIcon" class="material-icons-outlined">
            sync
          </span>

      </div>
      <div class="whatsapp">
        <p>{{ $t("contact.pWhatsApp") }}</p>
        <a href="https://wa.me/5521994689987" target="_blank"
          ><fa-icon id="icon" :icon="['fab', 'whatsapp']" />
          {{ $t("contact.linkWhatsApp") }}</a
        >
      </div>
      <div class="partnership">
        <p>{{ $t("contact.pPartner") }}</p>
        <div class="card">
          <a href="http:\\www.gereesbrasil.com.br" target="_blank"
            ><img src="../assets/images/geresBrasil.png" alt="geres"
          /></a>
          <a href="http:\\www.gereesbrasil.com.br" target="_blank"
            >Gerees Brasil</a
          >
        </div>
      </div>
    </div>
    <div class="toast" v-if="toast === true">
      <ToastForm />
    </div>
  </div>
</template>

<script>
import country from "../locales/paises-array.json";
import ToastForm from "./ToastForm.vue";

export default {
  data() {
    return {
      name: "",
      nacionality: "",
      age: "",
      tel: "",
      email: "",
      gender: "",
      gender2: "",
      country: country,
      toast: false,
      loading: false
    };
  },
  components: {
    ToastForm,
  },
  methods: {
   async sendDataToFirebase() {
      const ref = this.$firebase.database().ref("formContact");
      const id = ref.push().key;

      let button = document.querySelector("#btn")

        this.loading = true; // sync icon is visible
        button.setAttribute("disabled", true); // disable button
        button.style.background = "#c5c5c5"

      const payload = {
        id,
        receipt: null,
        name: this.name.toUpperCase(),
        nacionality: this.nacionality.toUpperCase(),
        age: this.age,
        tel: this.tel,
        email: this.email,
        gender: this.gender.toUpperCase(),
        gender_LGBT: this.gender2.toUpperCase(),
      };
     await ref.child(id).set(payload, (err) => {
        if (err) {
          alert(err);
        } else {
          this.name = "";
          this.nacionality = "";
          this.age = "";
          this.tel = "";
          this.email = "";
          this.gender = "";
          this.gender2 = "";
          this.toast = true;
        }
        this.loading = false; // sync icon is visible
        button.setAttribute("disabled", false); // disable button
        button.style.background = "#ff5500"
      });
    },
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
#contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background: #344146;
  color: #fff;
  padding-top: 20px;
}
.content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px 120px;
}
.form {
  height: 100%;
  width: 100%;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.form form {
  display: flex;
  flex-direction: column;
  padding-top: 30px;
}
.form form p {
  text-align: left;
  padding-bottom: 20px;
  font-size: 1.2em;
  font-weight: 200;
  color: #fff;
}
.form form span {
  font-size: 16px;
  width: 100%;
  font-weight: 200;
  margin-left: 10px;
  margin-top: 5px;
}
.form form input {
  width: 350px;
  height: 35px;
  padding: 10px;
  margin-bottom: 10px;
  border: none;
  background: #e8f0fe;
  border-radius: 30px;
  outline: none;
}
#gender2Text {
  color: #fff;
  animation: animation ease-in-out 6s;
}
@keyframes animation {
  0% {
    color: #ff9900;
  }
  100% {
    color: #fff;
  }
}
#select {
  width: 350px;
  height: 35px;
  padding: 10px;
  border: none;
  border-radius: 30px;
  background: #e8f0fe;
  margin-bottom: 10px;
  outline: none;
}
#btn {
  width: 100px;
  padding: 5px 10px;
  margin-top: 10px;
  font-weight: 200;
  border: none;
  border-radius: 30px;
  background: #ff5500;
  color: #fff;
  cursor: pointer;
}
#btn:hover {
  background: #b13b00;
}
#syncIcon {
  margin-top: -60px;
  margin-left: -100px;
  font-size: 40px;
  color: #ff9500;
  animation: rotate 1s linear infinite;
}
@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(-360deg);
  }
}
.whatsapp {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  align-items: center;
  margin-left: 50px;
  transition: 0.4s;
}
.whatsapp p {
  font-size: 1.2em;
  text-align: center;
  padding-bottom: 20px;
  font-weight: 200;
  color: #fff;
}
.whatsapp a {
  text-decoration: none;
  padding: 10px 20px;
  background: #2ad247;
  color: #fff;
  font-weight: 200;
  border-radius: 30px;
  margin-bottom: 50px;
}
.whatsapp a:hover {
  background: #1da033;
}
.whatsapp #icon {
  font-size: 25px;
}

.partnership {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  transition: 0.4s;
}
.partnership p {
  font-size: 1.3em;
  text-align: center;
  font-weight: 200;
  color: #fff;
}
.partnership a {
  font-size: 20px;
  color: #d98508;
  font-weight: bold;
  text-decoration: none;
}
.card {
  background: transparent;
  border: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

/*RESPONSIVE*/
@media (max-width: 1040px) {
  #contact {
    height: 100%;
  }
  .banner > h1 {
    font-size: 50px;
    padding: 10px;
  }
  .content {
    flex-direction: column;
    align-items: center;
    height: 100%;
    padding: 30px;
  }
  .form form {
    width: 100%;
    align-items: center;
  }
  .whatsapp {
    margin: 0;
    width: 100%;
  }
  .partnership {
    padding: 0 0 50px 0;
  }
  .form {
    padding: 30px 0;
    width: 100%;
  }

  .form form span {
  font-size: 16px;
  width: 100%;
  font-weight: 200;
  text-align: center;
}
}
@media (max-width: 640px) {
  .form form input {
    width: 100%;
  }
  #select {
    width: 100%;
  }
}
</style>